.setting-page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 84px;
  padding-bottom: 64px;

  .setting-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .setting-title {
      font-family: $poppins;
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
      text-align: center;
      letter-spacing: -1px;
      text-transform: uppercase;
      color: $primary-black;
      margin-bottom: 32px;
    }

    .setting-navbar {
      display: flex;
      justify-content: space-between;
      width: 320px;
      margin-bottom: 48px;

      .nav-item {
        cursor: pointer;
        font-family: $poppins;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        letter-spacing: -0.9px;
        text-transform: uppercase;
        color: $primary-black;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;

        &.active {
          color: $blue;
          border-bottom: 2px solid $blue;
        }
      }
    }

    .setting-form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .setting-input-title {
        font-family: $poppins;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.9px;
        color: $primary-black;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .setting-input {
        background: $white;
        box-shadow: $input-shadow;
        border-radius: 4px;
        font-family: $poppins;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.9px;
        padding: 16px;
        font-weight: 500;
        color: $primary-black;
        border: none;
        box-sizing: border-box;

        &:focus {
          outline: none;
        }
      }
    }

    .profile-setting {
      display: flex;
      flex-direction: column;

      .profile-photo-section {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        .profile-photo {
          width: 124px;
          height: 124px;
          border-radius: 4px;
          margin-right: 36px;
          background: url("https://upload.wikimedia.org/wikipedia/commons/e/e4/Elliot_Grieveson.png");
          background-size: cover;
          background-position: center center;
        }

        .upload-photo-button {
          cursor: pointer;
          position: relative;
          border: 1px solid $blue;
          border-radius: 4px;
          padding: 9px 14px;
          margin-right: 32px;

          .upload-input {
            position: unset;
            padding: 0;
            border: none;
            box-sizing: border-box;
            font-family: $poppins;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.9px;
            text-transform: uppercase;
            color: $blue;
            font-weight: normal;
          }

          .hidden-tooltip-container {
            right: 0;
          }

          svg {
            display: none;
          }
        }

        .remove-photo-button {
          cursor: pointer;
          font-family: $poppins;
          padding: 9px 0;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.9px;
          text-transform: uppercase;
          color: $primary-black;
          opacity: 0.5;
        }
      }

      .update-button {
        margin: auto;
        box-shadow: $box-shadow-button;
      }
    }

    .profile-password {
      width: 448px;

      .setting-password-container {
        position: relative;
        display: flex;
        flex-direction: column;

        .password-input {
          padding-right: 132px;
        }

        .change-button {
          position: absolute;
          right: 12px;
          top: 6px;
          height: 36px;
          padding: 10px 24px;
        }
      }
    }

    .profile-notification {
      display: flex;
      flex-direction: column;

      .setting-checkboxes {
        margin-top: 12px;
        margin-bottom: 60px;

        .checkbox-container {
          margin-bottom: 16px;
        }
      }

      .save-button {
        margin: auto;
      }
    }
  }
}

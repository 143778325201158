@import "./consts.scss";
@import "./navbar.scss";
@import "./emailConfirmBar.scss";
@import "./login.scss";
@import "./underReview.scss";
@import "./loanApproved.scss";
@import "./loanRejected.scss";
@import "./forms/";
@import "./inputs/";
@import "./dashboard/";
@import "./common/";
@import "./adminDashboard/";
@import "./tooltip.scss";

body {
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: $open;

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.merit-app {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $medium-white;
}

.primary-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 24px 78px;
  background: $button-gradient;
  box-shadow: $box-shadow-button;
  border: none;
  font-family: $poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: $white;

  &:focus {
    outline: unset;
  }
}

.green-button {
  cursor: pointer;
  background: $green-dark;
  border-radius: 7px;
  font-family: $poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.25px;
  color: $white;
  padding: 24px 78px;
  border: none;

  &:disabled {
    opacity: 0.8;
  }

  &:focus {
    outline: unset;
  }
}

.circle-percent-text {
  font-family: $poppins;
  font-weight: bold;
  font-size: 38px;
  line-height: 38px;
  text-transform: uppercase;
  color: $primary-black;
}

.dashboard-input {
  border: 1px solid rgba(25, 30, 89, 0.146);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 15px;
  font-family: $poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: rgba($black, 0.75);
  letter-spacing: -0.25px;

  &:focus {
    outline: none;
  }
}

.error-block {
  font-family: $poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.25px;
  color: $white;
  background: $error-red;
  border-radius: 4px;
  padding: 18px 54px;
}

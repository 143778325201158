.loan-request-container {
  display: flex;
  flex-direction: column;

  .section-content {
    display: flex;
    padding: 4px;

    .radio-container,
    .checkbox-container {
      margin-right: 24px;
      height: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .merit-reserve {
    flex-direction: column;
    padding: 0;

    .internal-section {
      border-bottom: 1px solid $primary-black;
      padding: 4px;
    }

    .internal-content {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
    }
  }

  .next-button {
    margin-left: auto;
  }
}

.form-content {
  display: none;

  &.active {
    display: flex;
  }

  .column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }

  .column-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
  }

  .column-2x2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 64px;
    grid-row-gap: 24px;
  }

  .form-title {
    font-family: $poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: $primary-black;
    margin-bottom: 48px;
  }

  .form-text-title {
    font-family: $poppins;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.25px;
    color: $primary-black;
  }

  .form-description-text {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: $primary-black;
  }

  .form-light-description {
    font-family: $poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    color: rgba($primary-black, 0.65);
  }

  .form-section {
    margin-bottom: 48px;

    .section-title {
      display: flex;
      align-items: center;
      font-family: $poppins;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: $primary-black;
      margin-bottom: 12px;

      .help-icon {
        cursor: help;

        &:focus {
          outline: none;
        }
      }
    }

    .section-sub-title {
      font-family: $poppins;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.5px;
      color: $primary-black;
      opacity: 0.65;
    }

    .section-content {
      border: 1px solid $primary-black;
    }

    // .section-block {}
  }
}

.button-section {
  margin-left: auto;

  .prev-button {
    background: transparent;
    box-shadow: none;
    color: $primary-black;
    padding: 24px 48px;
  }

  // .next-button {}
}

.admin-super-navbar {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  padding-right: 48px;
  background-color: $white;

  .page-title-section {
    font-family: $poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $blue-dark;
  }

  .super-navbar-icons {
    position: relative;
    display: flex;
    align-items: center;

    .notification-bell {
      cursor: pointer;
      margin-right: 32px;
    }

    .log-out-node {
      cursor: pointer;
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $primary-black;
    }
  }

  .loan-page-title {
    display: flex;
    align-items: center;

    .back-arrow {
      cursor: pointer;
      min-height: 32px;
      min-width: 32px;
    }

    .title {
      cursor: pointer;
      white-space: nowrap;
      margin-right: 16px;
    }
  }
}

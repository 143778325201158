@import "./home/";
@import "./booster/";
@import "./setting.scss";

.dashboard-screen-container {
  display: flex;
  flex: 1;

  .coming-soon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $poppins;
    font-size: 72px;
  }
}

.merit-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: $white;
  padding: 0 36px;

  .merit-links {
    display: flex;
    height: 100%;

    .merit-nav-link {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: $poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 14px;
      letter-spacing: -0.9px;
      text-transform: uppercase;
      color: $primary-black;
      padding: 0 42px;
      border-bottom: 2px solid transparent;
      text-decoration: none;

      &.active {
        color: $blue;
        border-bottom: 2px solid $blue;
      }
    }
  }

  .merit-navbar-icons {
    display: flex;

    .merit-navbar-link {
      position: relative;
      text-decoration: none;
      height: 32px;
    }

    .cross-back {
      cursor: pointer;
    }

    .navbar-notification-container {
      position: relative;
      margin-right: 32px;
      height: 32px;

      .notification-dialog {
        right: -32px;
      }
    }

    .navbar-icon {
      cursor: pointer;
    }
  }
}

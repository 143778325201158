@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file) format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

// @include font('Gotham-Bold', '../fonts/Gotham-Bold.otf');
// $bold: 'Gotham-Bold';
$poppins: "Poppins";

$black:#000;
$primary-black:#323232;

$green:#008551;
$green-dark: #00a688;

$red:#ff000d;
$red-dark:#f00;
$error-red:#e94b4b;
$message-error-red:#ff4c4c;

$yellow:#ffb900;

$white:#fff;
$medium-white:#f3f3f8;
$dark-white:#dfdfea;
$cream-white:#e7e7ef;

$amount-input-background:#f4f5ff;

$notification-white:#f7f7fa;

$border-white:#dfdfdf;

$blue: #4c5aff;
$blue-medium:#3946e9;
$blue-dark:#000432;

$primary-background-color:$black;
$card-border-color:$green;

$green-gradient:linear-gradient(169.56deg, #2ee6c5 -82.21%, #18b794 124.45%);
$background-gradient:linear-gradient(169.56deg, #ff7f2d -82.21%, #784cff 124.45%);
$button-gradient: linear-gradient(169.56deg, #8393ff -82.21%, #4c5aff 125.84%);

$box-shadow-button: 0 16px 24px rgba(50, 50, 50, 0.1533);
$box-shadow-box:0 16px 24px rgba(25, 30, 89, 0.145597);
$admin-card-shadow: 0 2px 10px rgba(76, 90, 255, 0.0777426);
$notification-shadow: 0 32px 48px rgba(0, 0, 0, 0.12134);
$input-shadow:0 16px 32px rgba(25, 30, 89, 0.145597);
$reject-shadow:0 16px 24px rgba(50, 50, 50, 0.1533);

$rhythm: 12px;

$mobile-small: 365px;
$mobile: 550px;
$mobile-large: 581px;
$tablet: 768px;
$tablet-medium: 906px;
$tablet-large: 1214px;
$desktop: 1380px;
$desktop-large: 1700px;

@keyframes spin {

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin mobile-small {
  @media only screen and (max-width: $mobile-small) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-large {
  @media only screen and (max-width: $mobile-large) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin tablet-medium {
  @media only screen and (max-width: $tablet-medium) {
    @content;
  }
}

@mixin tablet-large {
  @media only screen and (max-width: $tablet-large) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin desktop-large {
  @media only screen and (max-width: $desktop-large) {
    @content;
  }
}

@mixin touch {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin mouse {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin moz {
  @-moz-document url-prefix() {
    @content;
  }
}

//Scroll-bar
@mixin scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: rgba($primary-black, 0.25) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($primary-black, 0.25);
    border: 0 none $white;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0 none $white;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($primary-black, 0.35);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba($primary-black, 0.5);
  }

  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }

  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
}

.notification-dialog {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 340px;
  height: 388px;
  background: $white;
  box-shadow: $notification-shadow;
  border-radius: 4px;
  padding: 24px 16px;
  box-sizing: border-box;
  z-index: 100;

  .notification-title {
    font-family: $poppins;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $blue-dark;
    margin-bottom: 24px;
  }

  .notification-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 296px;

    @include scroll-bar;

    .notification-list-item {
      padding: 16px;
      box-sizing: border-box;
      background: $notification-white;
      border-radius: 4px;
      margin-bottom: 16px;

      &:first-child {
        margin-bottom: 0;
      }

      .notification-list-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .notification-list-item-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 190px;

          .company-logo-container {
            min-height: 32px;
            width: 100%;

            .company-text {
              font-family: $poppins;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: $blue-dark;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.user-notification-dialog {

            .company-logo {
              display: none;
            }
          }
        }

        .notification-list-item-time {
          font-family: $poppins;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: $blue-dark;
          white-space: nowrap;
        }
      }

      .notification-list-item-description {
        font-family: $poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $blue-dark;
      }
    }
  }
}

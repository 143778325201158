.document-upload-content-container {

  .document-upload-head {
    margin-bottom: 8px;
  }

  .document-upload-content {
    border: 1px solid $primary-black;
    padding: 12px;

    .document-upload-upload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 4px;
      border-bottom: 1px solid $primary-black;
      margin-bottom: 12px;

      .nda-link {
        text-decoration: none;
      }
    }
  }
}

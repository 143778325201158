.radio-container {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }

  .title {
    cursor: pointer;
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-black;
    letter-spacing: -0.25px;
    margin-left: 4px;
  }

  .radio-text-input {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: $primary-black;
    width: 176px;
    border: unset;
    padding: 0;
    padding-bottom: 4px;
    border-bottom: 1px solid $primary-black;
    background-color: transparent;
    box-sizing: border-box;
    margin-left: 4px;

    &::placeholder {
      color: $primary-black;
    }

    &:focus {
      outline: none;
    }
  }

  .validation-checkbox {
    cursor: pointer;
    position: absolute;
    height: 18px;
    width: 18px;
    margin: 0;
    margin-left: 3px;
    opacity: 0;
  }
}

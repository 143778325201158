.chat-dialog-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 24px;
  right: 24px;
  background: $blue;
  border-radius: 4px;
  width: 312px;
  box-sizing: border-box;
  z-index: 10;

  .chat-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $blue-medium;
    border-radius: 4px;
    padding: 10px;
    padding-left: 16px;

    .chat-status {
      display: flex;
      align-items: center;
      font-family: $poppins;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $white;

      .status-ball {
        height: 12px;
        width: 12px;
        background: $green-dark;
        border-radius: 6px;
        margin-right: 8px;
      }
    }

    .call-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $green-dark;
      border-radius: 4px;
      padding: 6px;
    }
  }

  .chat-nodes-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    overflow: auto;
    height: 400px;
    max-height: 400px;

    @include scroll-bar;

    .spacer-node {
      margin-top: auto;
    }

    .message-container {
      display: flex;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .my-message {
      padding-right: 18px;
      justify-content: flex-start;
    }

    .your-message {
      padding-left: 18px;
      justify-content: flex-end;
    }

    .message-text {
      width: fit-content;
      background: $white;
      border-radius: 4px;
      padding: 16px;
      font-family: $poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba($blue-dark, 0.75);
    }
  }

  .chat-message-input {
    position: relative;
    padding: 16px;

    .message-input {
      height: 64px;
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $primary-black;
      background: $white;
      border-radius: 4px;
      border: none;
      padding-left: 20px;
      padding-right: 64px;
      box-sizing: border-box;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .send-button {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $green-dark;
      border-radius: 4px;
      padding: 6px;
    }
  }
}

@import "./cardDialog.scss";
@import "./companyLogo.scss";
@import "./statusNode.scss";
@import "./scoreNode.scss";
@import "./commonTable.scss";
@import "./errorBlock.scss";
@import "./userMenu.scss";
@import "./messageToast.scss";
@import "./notificationDialog.scss";
@import "./documentUpload.scss";
@import "./coverLoader.scss";
@import "./requestedAmountInput.scss";

.black-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black, 0.65);
  z-index: 100;
}

.form-sidebar {
  margin-top: 36px;

  .form-sidebar-sticky {
    position: sticky;
    top: 32px;

    .sidebar-node {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      margin-bottom: 14px;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: $primary-black;
      font-family: $poppins;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        border-bottom: 2px solid $blue;
        background-color: $white;
      }
    }
  }
}

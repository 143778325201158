.company-logo-container {
  display: flex;
  align-items: center;

  .company-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    background: rgba($blue-dark, 0.15);
    border-radius: 4px;
    font-family: $poppins;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: rgba($blue-dark, 0.5);
    margin-right: 8px;
  }

  .company-text {
    font-family: $poppins;
    font-weight: 500;
    font-size: 15px;
    color: $blue-dark;
  }
}

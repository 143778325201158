.admin-sidebar-component {
  background: $white;
  min-width: 212px;

  .sidebar-logo {
    margin: 32px;
    margin-bottom: 102px;
  }

  .sidebar-nav-container {
    display: flex;
    flex-direction: column;

    .sidebar-link {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 48px;
      margin: 8px 0;
      opacity: 0.5;
      border-right: 2px solid transparent;
      text-decoration: none;
      height: 48px;
      box-sizing: border-box;

      .link-nav {
        font-family: $poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $blue-dark;
      }

      &.active {
        opacity: 1;
        border-right: 4px solid $white;
        background: rgba($blue-dark, 0.1);

        .link-nav {
          color: $blue;
        }
      }
    }
  }
}

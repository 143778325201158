.card-dialog-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 4px;
  padding: 24px 32px;
  width: 400px;
  box-sizing: border-box;
  z-index: 101;

  .cross-icon {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .dialog-column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }

  .dialog-column-cvv {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 32px;
  }

  .hidden-card-input {
    cursor: default;
    position: absolute;
    width: 100%;
    bottom: 0;
    border: none;
    height: 1px;
    opacity: 0;
    background-color: $primary-background-color;

    &:focus {
      outline: none;
    }
  }

  .card-dialog-title {
    font-family: $poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: $primary-black;
    margin-bottom: 24px;
  }

  .card-input-group {
    position: relative;
    margin-bottom: 24px;

    .input-label {
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.25px;
      color: $black;
      opacity: 0.75;
      margin-bottom: 12px;
    }

    .dashboard-input {
      width: 100%;
      height: 68px;
    }

    .month-input-column {
      grid-column-gap: 8px;
    }

    .month-inputs {
      text-align: center;
    }
  }

  .card-dialog-button {
    margin: auto;
  }
}

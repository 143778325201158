.credit-preview-card-container {

  .credit-preview-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 219px;
    height: 95px;
    background: linear-gradient(137.86deg, rgba(0, 166, 136, 0.14576) -14.74%, rgba(76, 90, 255, 0.150732) 271.21%);
    border-radius: 8px;
    padding: 20px 24px 16px 24px;
    box-sizing: border-box;
    border: 2px solid transparent;

    &.selected {
      border: 2px solid $card-border-color;
    }

    .card-number-container {
      display: flex;
      justify-content: space-between;
    }

    .card-detail {
      font-family: $poppins;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.25px;
      color: $primary-black;
      margin-right: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .card-bubbles {
      position: absolute;
      right: 0;
      bottom: 0;
      border-bottom-right-radius: 8px;
    }
  }

  .credit-preview-controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    .control-node {
      cursor: pointer;
      font-family: $poppins;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.25px;
      color: $blue;
      margin-right: 48px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.form-input-container {

  .form-input-label {
    font-family: $poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    color: $primary-black;
    opacity: 0.65;
    margin-bottom: 6px;
  }

  .form-input {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: $primary-black;
    width: 100%;
    border: unset;
    padding: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid $primary-black;
    background-color: transparent;
    box-sizing: border-box;

    &::placeholder {
      color: $primary-black;
    }

    &:focus {
      outline: none;
    }
  }

  .form-input-description {
    font-family: $poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    color: $primary-black;
    opacity: 0.65;
    margin-top: 6px;
  }
}

.admin-common-table {
  display: flex;
  flex-direction: column;

  .common-table-header {
    display: grid;
    grid-template-columns: 300px 204px 204px 204px 104px;
    padding: 0 56px;
    padding-top: 28px;
    padding-bottom: 16px;

    .table-head {
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $blue-dark;
      opacity: 0.5;
    }
  }

  .common-table-body {
    padding: 0 32px;
    max-height: calc(100vh - 208px);
    overflow: auto;
    margin-right: auto;

    @include scroll-bar; // may remove this style

    .common-list-item {
      display: grid;
      grid-template-columns: 300px 204px 204px 204px 104px;
      width: fit-content;
      background: rgba($white, 0.5);
      border-radius: 4px;
      padding: 18px 24px;
      margin-bottom: 16px;
      text-decoration: none;

      .table-text-dark {
        font-family: $poppins;
        font-weight: 500;
        font-size: 15px;
        color: $blue-dark;

        &.light {
          opacity: 0.75;
        }
      }

      .common-list-column {
        display: flex;
        align-items: center;

        .company-logo-container {
          width: 100%;

          .company-text {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
        }
      }
    }
  }
}

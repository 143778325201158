.checkbox-container {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  .title {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-black;
  }

  .validation-checkbox {
    cursor: pointer;
    position: absolute;
    height: 24px;
    width: 24px;
    opacity: 0;
  }
}

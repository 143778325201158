.authentication-component {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logo-svg {
    margin-bottom: 20px;
  }

  .authentication-error {
    margin-bottom: 16px;
    opacity: 0;
    min-height: 50px;
    box-sizing: border-box;

    &.show {
      transition: 0.3s opacity;
      opacity: 1;
    }
  }

  .authentication-block {
    display: flex;
    flex-direction: column;
    width: 352px;

    .authentication-title {
      font-family: $poppins;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -1px;
      color: $primary-black;
      margin-bottom: 18px;
    }

    .authentication-form {
      display: flex;
      flex-direction: column;
      background: $white;
      border-radius: 4px;
      padding: 48px 0;
      margin-bottom: 32px;

      .form-input-container {
        margin: 0 20px;
        margin-bottom: 30px;

        .form-input {
          padding-bottom: 8px;
        }
      }

      .authentication-submit {
        width: 200px;
        margin: auto;
        padding: 24px 36px;
        margin-bottom: 48px;
        text-decoration: none;
        text-transform: uppercase;
      }

      .authentication-agreement {
        font-family: $poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -1px;
        color: $black;
        opacity: 0.5;
        margin: 0 12px;
      }
    }

    .authentication-switch {
      font-family: $poppins;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -1px;
      color: $primary-black;
      text-align: center;
      text-decoration: none;
      width: 100%;
    }
  }
}

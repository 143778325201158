.under-review-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .under-review {
    display: flex;
    flex-direction: column;
    width: 648px;
    background: $background-gradient;
    box-shadow: $box-shadow-button;
    padding: 72px 30px;
    padding-bottom: 106px;

    .review-icon {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
      animation: spin 2s cubic-bezier(0.25, 1, 1, 0.8) infinite;
    }

    .review-text {
      font-family: $poppins;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.75;
      color: $white;
    }
  }
}

.admin-home-dashboard {
  padding: 32px;
  box-sizing: border-box;

  .fund-analysis {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-column-gap: 32px;
    margin-bottom: 32px;

    .fund-data-card {
      background: $white;
      box-shadow: $admin-card-shadow;
      border-radius: 8px;
      padding: 32px;
      padding-right: 0;
      height: 150px;
      box-sizing: border-box;

      .fund-data-head {
        font-family: $poppins;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $primary-black;
        opacity: 0.75;
        margin-bottom: 18px;
      }

      .fund-data-content {
        font-family: $poppins;
        font-weight: bold;
        font-size: 38px;
        line-height: 38px;
        color: $primary-black;
      }

      &.green {
        background: $green-dark;

        .fund-data-head {
          color: $white;
          margin-bottom: 12px;
        }

        .fund-data-content {
          color: $white;
          font-size: 48px;
          line-height: 48px;
        }
      }
    }
  }

  .approved-history-section {

    .approved-history-title {
      font-family: $poppins;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $primary-black;
      margin-bottom: 16px;
    }

    .approved-history-list-container {
      background: $white;
      box-shadow: $admin-card-shadow;
      border-radius: 8px;
      padding: 32px;
      box-sizing: border-box;
      max-height: calc(100vh - 434px);
      overflow: auto;

      @include scroll-bar;

      .approved-list-item {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr;
        padding-bottom: 24px;
        border-bottom: 1px solid $cream-white;
        margin-bottom: 32px;
        text-decoration: none;

        &:last-child {
          margin-bottom: 0;
        }

        .approved-list-item-text {
          font-family: $poppins;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: -0.3px;
          color: $primary-black;

          &.overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.lite {
            font-weight: 500;
          }
        }
      }
    }
  }
}

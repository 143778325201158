.application-details-container {
  display: grid;
  grid-template-columns: 380px 408px;
  grid-column-gap: 32px;
  padding: 48px 32px;

  .detail-title {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blue-dark;
    opacity: 0.5;
    margin-bottom: 12px;
  }

  .detail-desc {
    font-family: $poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $blue-dark;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    &:visited {
      color: $blue-dark;
    }
  }

  .application-bussiness-details {
    padding: 16px;
  }

  .detail-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 18px 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .text-section {
      margin-right: auto;
      width: 100%;
    }

    .download-icon {
      cursor: pointer;
      margin-right: 24px;
      margin-top: auto;
      min-height: 32px;
      min-width: 32px;
    }
  }

  .application-bussiness-request {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 32px;
    border: 1px solid $blue-dark;

    .detail-block-dark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 32px;

      &:last-child {
        margin-bottom: 32px;
        border-bottom: 1px solid rgba($white, 0.25);
      }
    }

    .application-divider {
      width: 100%;
      opacity: 0.25;
      border-bottom: 1px solid $blue-dark;
      margin-bottom: 32px;
    }

    .month-rate {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      margin-bottom: 32px;

      .rate-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $white;
        border-radius: 4px;
        padding: 24px 0;

        &.approved {
          background: none;
          border: 1px solid $blue-dark;
        }

        .rate-card-title {
          margin-bottom: 12px;

          input {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            color: inherit;
            border: none;
            background: none;
            min-width: 0;
            width: 32px;
            padding: 0;
            text-align: center;

            &:focus {
              outline: none;
            }
          }
        }

        .rate-card-desc {
          margin-bottom: 0;
        }
      }
    }

    .installment-deduct {
      display: flex;

      .deduct-title {
        width: 156px;
      }

      .deduct-select {
        position: relative;
        display: flex;
        align-items: center;
        min-width: 72px;

        select,
        span {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          font-family: $poppins;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: $blue-dark;
          background: transparent;
          padding: 12px;
          border: none;
          text-align: center;

          option {
            color: $blue-dark;
          }

          &:focus {
            outline: none;
          }
        }

        svg {
          pointer-events: none;
          position: absolute;
          right: 0;
          transform: rotate(270deg);
        }
      }
    }

    .button-section {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      .decline {
        cursor: pointer;
        font-family: $poppins;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: $blue-dark;
        border: none;
        padding: 15px 24px;
        background: transparent;

        &:focus {
          outline: none;
        }
      }

      .approve {
        cursor: pointer;
        background: $white;
        border-radius: 4px;
        font-family: $poppins;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: $blue-dark;
        border: none;
        padding: 15px 24px;

        &:focus {
          outline: none;
        }
      }

      .approved {
        cursor: auto;
        color: $white;
        background-color: $green-dark;
      }

      .declined {
        cursor: auto;
        color: $white;
        background-color: $red-dark;
      }
    }
  }

  &.column-three-details {
    padding: 64px 48px;
    grid-template-columns: 350px 350px 350px;
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 16px;
    grid-column-gap: 32px;
    grid-auto-flow: column;

    @include desktop-large {
      grid-template-columns: 330px 330px 330px;
    }

    @include desktop {
      grid-template-columns: 300px 300px 300px;
    }

    .detail-block {
      margin-bottom: 0;
    }
  }
}

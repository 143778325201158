#help-tooltip {
  width: 460px;
  padding: 16px;
  background: $white;
  font-family: $poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.75px;
  color: rgba($primary-black, 0.65);

  &.show {
    opacity: 1;
  }

  &::before {
    content: none;
  }

  &::after {
    all: unset;
    content: "";
    position: absolute;
    border: none;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }

  &.place-right::after {
    top: 50%;
    left: -10px;
    background: $white;
    transform: translate(0, -50%) rotate(45deg);
  }

  &.place-top::after {
    right: 50%;
    bottom: -10px;
    background: $white;
    transform: translate(50%, 0) rotate(45deg);
  }

  &.place-left::after {
    top: 50%;
    right: -10px;
    background: $white;
    transform: translate(0, -50%) rotate(45deg);
  }

  &.place-bottom::after {
    right: 50%;
    top: -10px;
    background: $white;
    transform: translate(50%, 0) rotate(45deg);
  }
}

#upload-tooltip {
  background-color: $error-red;
  font-family: $poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.25px;
  color: $white;
  padding: 12px 16px;
  border-radius: 4px;
  white-space: nowrap;

  &.show {
    opacity: 1;
  }

  &::after {
    content: none;
  }

  &::before {
    all: unset;
    content: "";
    position: absolute;
    border: none;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }

  &.place-right::before {
    top: 50%;
    left: -6px;
    transform: translate(0, -50%) rotate(45deg);
    background: linear-gradient(45deg, $error-red 0%, $error-red 50%, transparent 50%, transparent 100%);
  }

  &.place-top::before {
    right: 50%;
    bottom: -6px;
    transform: translate(50%, 0) rotate(45deg);
    background: linear-gradient(315deg, $error-red 0%, $error-red 50%, transparent 50%, transparent 100%);
  }

  &.place-left::before {
    top: 50%;
    right: -6px;
    transform: translate(0, -50%) rotate(45deg);
    background: linear-gradient(225deg, $error-red 0%, $error-red 50%, transparent 50%, transparent 100%);
  }

  &.place-bottom::before {
    right: 50%;
    top: -6px;
    transform: translate(50%, 0) rotate(45deg);
    background: linear-gradient(135deg, $error-red 0%, $error-red 50%, transparent 50%, transparent 100%);
  }
}

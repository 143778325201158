.payment-list-node {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(25, 30, 89, 0.145597);
  padding-left: 16px;
  padding-bottom: 12px;
  padding-right: 84px;
  margin-bottom: 12px;

  &:last-child {
    border-bottom: unset;
    margin-bottom: 0;
  }

  .info-block {

    .upper-text {
      font-family: $poppins;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: -0.25px;
      color: $primary-black;
      margin-bottom: 9px;
    }

    .lower-text {
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.25px;
      color: $primary-black;
      opacity: 0.5;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .right {
      text-align: right;
    }
  }
}

.ownership-director-management-container {
  display: flex;
  flex-direction: column;

  .ownership-more-10 {

    .section-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;

      .ownership-more-radio {
        display: flex;

        .radio-container {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .ownership-more-help {
    line-height: 16px;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .board-directors-text {
    margin-top: -12px;
    margin-bottom: 48px;
  }

  .owner-type {

    .owner-type-radio {
      display: flex;
      padding: 4px;
      border-bottom: 1px solid $primary-black;

      .radio-container {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .owner-type-text {
      padding: 8px;
    }
  }

  .equity-intrest {

    .section-content {
      padding: 16px;
    }
  }

  .owner-identification {
    margin-bottom: 12px;
  }

  .owner-identification-files {

    .section-content {

      .owner-identification-files-radio {
        display: flex;
        flex-wrap: wrap;
        padding: 4px;
        border-bottom: 1px solid $primary-black;
        margin-bottom: 8px;

        .radio-container {
          margin-bottom: 8px;
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .owner-identification-files-upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;

        .upload-text {
          font-family: $poppins;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -1px;
          color: $primary-black;
          opacity: 0.5;
          margin-bottom: 8px;
        }
      }
    }
  }
}

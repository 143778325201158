.booster-card {
  cursor: pointer;
  width: 318px;
  background: $white;
  box-shadow: $box-shadow-box;
  border-radius: 4px;
  padding: 48px 32px 62px 32px;
  box-sizing: border-box;

  .booster-card-title {
    font-family: $poppins;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 700;
    color: $primary-black;
    margin-bottom: 16px;
  }

  .booster-card-content {
    font-family: $poppins;
    font-size: 14px;
    line-height: 16px;
    color: $primary-black;
  }
}

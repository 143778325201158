.final-submission {
  display: flex;
  flex-direction: column;

  .certification-radios-section {

    .section-content {

      .certification-radios-content {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid $primary-black;

        .certification-radios-text {
          margin-right: 48px;
          font-size: 14px;
          line-height: 15px;
        }

        .certification-radio {
          display: flex;

          .radio-container {
            margin-right: 24px;
            margin-bottom: auto;
            margin-top: 6px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .certification-radios-input {
        padding: 12px 24px;
      }
    }
  }

  .nda-document-upload {
    margin-bottom: 48px;
  }

  .merit-documentation {
    margin-bottom: 16px;
  }

  .documentation-checkboxes {
    display: flex;
    flex-direction: column;

    .docu-checkbox-node {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .checkbox-container {
        margin-right: 12px;
      }

      .view-text {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  .agreement-check {
    margin-bottom: 48px;
  }
}

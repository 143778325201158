@import "./adminSidebar.scss";
@import "./subNavbar.scss";
@import "./superNavbar.scss";
@import "./chatDialog.scss";
@import "./adminHomePage.scss";
@import "./applicationDetails/";

.admin-dashboard-screen-container {
  flex: 1;
  height: 100%;
  display: flex;

  .admin-dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@import "./loanRequest.scss";
@import "./formSidebar.scss";
@import "./bussinessProfileForm.scss";
@import "./ownershipDirectorManagementForm.scss";
@import "./finalSubmissionForm.scss";
@import "./form.scss";

.merit-form-container {
  display: flex;
  padding-bottom: 36px;

  .merit-form {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 64px;
    margin: auto;
    width: 100%;
    max-width: 1150px;

    .merit-form-section {
      padding-top: 36px;
    }
  }
}

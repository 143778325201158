.status-node {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  padding: 0 10px;
  border-radius: 20px;
  font-family: $poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  &.approved,
  &.upcoming {
    background: rgba($green, 0.15);
    color: $green-dark;
  }

  &.waiting {
    background: rgba($blue-dark, 0.15);
    color: $blue-dark;
  }

  &.declined,
  &.overdue {
    background: rgba($red, 0.15);
    color: $red-dark;
  }
}

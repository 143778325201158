.upload-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  .upload-input {
    cursor: pointer;
    position: relative;
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: $primary-black;
    padding: 9px 28px;
    border: 1px solid $primary-black;
    text-overflow: ellipsis;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    max-height: 36px;

    input {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    .clear-icon {
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translate(0, -50%);
      min-height: 24px;
      min-width: 24px;
    }
  }

  .hidden-tooltip-container {
    position: absolute;
    right: 24px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 2px;
    background-color: $red;
    z-index: -1;

    &:focus {
      outline: none;
    }
  }

  .help-icon {
    cursor: help;

    &:focus {
      outline: none;
    }
  }

  .upload-error {
    top: 36px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.requested-amount-input {
  display: flex;
  align-items: center;
  position: relative;
  height: 54px;

  &::before {
    content: "$";
    position: absolute;
    left: 12px;
  }

  .amount-input {
    background: $white;
    border-radius: 4px;
    width: 144px;
    box-sizing: border-box;
    padding: 14px 8px;
    padding-left: 30px;
    border: 0;
    margin-right: 12px;
    font-family: $poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $blue-dark;

    &.disbaled {
      pointer-events: none;
    }

    &:focus {
      border: 0;
      outline: 0;
    }
  }

  .edit {
    cursor: pointer;
    font-family: $poppins;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: $blue;
  }
}

.user-menu {
  position: absolute;
  right: 0;
  top: 36px;
  background-color: $white;
  border-radius: 8px;
  padding: 18px;
  box-sizing: border-box;
  box-shadow: $box-shadow-box;

  .user-menu-link {
    font-family: $poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    opacity: 0.75;
    color: $primary-black;
    white-space: nowrap;
    text-decoration: none;
  }

  div {
    margin-top: 12px;
  }
}

.score-node {
  display: flex;
  align-items: center;

  .score-ball {
    height: 12px;
    width: 12px;
    border-radius: 20px;
    margin-right: 8px;
  }

  .score-text {
    font-family: $poppins;
    font-weight: 500;
    font-size: 15px;
    color: $blue-dark;
    line-height: 12px;
  }

  &.bad {

    .score-ball {
      background: $red;
    }
  }

  &.fair {

    .score-ball {
      background: $yellow;
    }
  }

  &.good {

    .score-ball {
      background: $green-dark;
    }
  }

  &.excellent {

    .score-ball {
      background: $green;
    }
  }
}

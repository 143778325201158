.message-toast-portal-container {
  position: fixed;
  right: 60px;
  top: 110px;
  z-index: 99;

  .message-toast-container {
    position: relative;
    display: flex;
    align-items: center;
    background: $white;
    box-shadow: 0 48px 64px rgba(0, 4, 50, 0.297384);
    border-radius: 4px;
    padding: 32px;
    padding-right: 72px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .message-icon {
      margin-right: 32px;
    }

    .message-content {

      .message-title {
        font-family: $poppins;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.25px;
        margin-bottom: 9px;

        &.success {
          color: $green-dark;
        }

        &.error {
          color: $message-error-red;
        }
      }

      .message-description {
        font-family: $poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.25px;
        color: $primary-black;
      }
    }

    .cross-icon {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;
      opacity: 0.7;
    }
  }
}

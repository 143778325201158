@import "./creditCard.scss";
@import "./historyListItem.scss";

.builder-payments {
  flex: 1;
  padding: 48px;
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-column-gap: 32px;

  .left-section {
    display: flex;
    flex-direction: column;

    .total-paid-amount {
      position: relative;
      display: flex;
      background-color: $green-dark;
      border-radius: 8px;
      padding: 32px 0 42px 32px;
      margin-bottom: 32px;
      height: 150px;
      box-sizing: border-box;

      .paid-details {
        margin-left: 24px;

        .title {
          font-family: $poppins;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $white;
          opacity: 0.75;
          margin-bottom: 12px;
        }

        .amount {
          font-family: $poppins;
          font-weight: 600;
          font-size: 48px;
          line-height: 48px;
          text-transform: uppercase;
          color: $white;
        }
      }

      .looper-background {
        position: absolute;
        right: -35%;
        top: -20%;
      }
    }

    .make-payment-section {
      flex: 1;
      display: flex;
      flex-direction: column;

      .make-payment-title {
        font-family: $poppins;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: $primary-black;
        margin-bottom: 16px;
      }

      .make-payment-content {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        background: $white;
        border-radius: 8px;
        padding: 32px 24px;

        .make-payment-input-group {
          margin-bottom: 32px;

          .make-payment-input-title {
            font-family: $poppins;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.25px;
            color: $black;
            opacity: 0.75;
            margin-bottom: 12px;
          }

          .make-payment-input-content {

            .amount-intrest {
              display: flex;
              box-sizing: border-box;
              border-radius: 8px;
              overflow: hidden;
              background-color: $amount-input-background;

              .amount {
                flex: 1;
                padding: 20px 15px;
                font-family: $poppins;
                font-weight: 600;
                font-size: 21px;
                line-height: 24px;
                color: $primary-black;
                border: unset;
                box-sizing: border-box;
                width: 100%;
              }
            }

            .saved-cards {
              display: flex;
              overflow: auto;

              @include scroll-bar;

              .credit-preview-card-container {
                margin-right: 16px;
              }

              .add-new-card {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(25, 30, 89, 0.145597);
                box-sizing: border-box;
                border-radius: 8px;
                font-family: $poppins;
                font-weight: 600;
                font-size: 15px;
                line-height: 15px;
                letter-spacing: -0.25px;
                color: $blue;
                width: 100%;
                min-width: 156px;
                height: 95px;
                padding: 24px 18px;
              }
            }
          }
        }

        .pay-now-button {
          margin: auto;
        }
      }

      .cover-loader {
        border-radius: 8px;
      }
    }
  }

  .right-section {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 32px;

    .loan-details-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 32px;

      .amount-detail-card {
        background-color: $white;
        border-radius: 8px;
        padding: 32px 0 44px 32px;
        height: 150px;
        box-sizing: border-box;

        .title {
          font-family: $poppins;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $primary-black;
          opacity: 0.75;
          margin-bottom: 12px;
        }

        .amount {
          font-family: $poppins;
          font-weight: 600;
          font-size: 38px;
          line-height: 38px;
          text-transform: uppercase;
          color: $primary-black;
        }
      }

      .on-time-card {
        display: flex;
        position: relative;
        background-color: $white;
        border-radius: 8px;
        padding: 32px 0;
        height: 210px;
        box-sizing: border-box;

        .on-time-title {
          position: absolute;
          top: 32px;
          left: 32px;
          font-family: $poppins;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $primary-black;
          opacity: 0.75;
          width: 90px;
        }

        .circle-percent {
          margin: auto;
        }
      }

      .next-payment-card {
        background-color: $white;
        border-radius: 8px;
        padding: 32px;
        padding-bottom: 60px;
        height: 210px;
        box-sizing: border-box;

        .next-payment-title {
          font-family: $poppins;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: $primary-black;
          opacity: 0.75;
          width: 120px;
          margin-bottom: 24px;
        }

        .next-payment-date {
          font-family: $poppins;
          font-weight: 600;
          font-size: 28px;
          line-height: 28px;
          color: $primary-black;
          margin-bottom: 16px;
        }

        .next-payment-remaining {
          font-family: $poppins;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: $green-dark;

          &.overdue {
            color: $error-red;
          }

          span {
            font-weight: bold;
          }
        }
      }
    }

    .loan-details-history-section {
      display: grid;
      grid-template-columns: 10fr 9fr;
      grid-column-gap: 32px;

      .block-title {
        font-family: $poppins;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: $primary-black;
      }

      .payment-history {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;

        .reload-icon {
          cursor: pointer;
          position: absolute;
          height: 18px;
          width: 18px;
          right: 12px;
          top: 3px;
          fill: $primary-black;
        }

        .payment-history-title {
          margin-bottom: 16px;
        }

        .payment-history-list {
          flex: 1;
          background: $white;
          border-radius: 8px;
          overflow: auto;
          padding: 32px;
          min-height: 185px;
          max-height: calc(100vh - 724px);

          @include scroll-bar;
        }
      }

      .your-plan {
        display: flex;
        flex-direction: column;

        .your-plan-title {
          margin-bottom: 16px;
        }

        .your-plan-content {
          flex: 1;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 12px;
          grid-row-gap: 24px;
          background: $white;
          border-radius: 8px;
          overflow: auto;
          padding: 32px;

          .plan-block {
            color: $primary-black;
            font-family: $poppins;
            letter-spacing: -0.25px;

            &.extended {
              grid-area: 2 / 2 / 4 / 3;
            }

            &.white-text {

              .plan-description {
                white-space: nowrap;
              }
            }

            .plan-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              opacity: 0.5;
              margin-bottom: 9px;
            }

            .plan-description {
              font-weight: 600;
              font-size: 15px;
              line-height: 16px;
              letter-spacing: -0.25px;
            }
          }
        }
      }
    }
  }
}

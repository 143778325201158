@import "./boosterCard.scss";
@import "./boosterUpload.scss";

.booster-container {
  flex: 1;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-success {
    background: $background-gradient;
    max-width: 648px;
    box-sizing: border-box;
    box-shadow: $box-shadow-button;
    border-radius: 4px;
    font-family: $poppins;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.6px;
    color: $white;
    padding: 32px;
    margin-bottom: 32px;
  }

  .booster-list-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 48px;
  }

  .booster-upload-container {
    margin: auto;
  }
}

.booster-upload-container {
  display: flex;
  flex-direction: column;

  .upload-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 694px;
    height: 451px;
    margin-bottom: 24px;
    background: $white;
    border: 1px solid $border-white;
    box-sizing: border-box;
    border-radius: 4px;

    .upload-click-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .hidden-file-input {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:focus {
          outline: none;
        }
      }

      .cloud-icon {
        margin-bottom: 16px;
      }

      .drag-text {
        font-family: $poppins;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
        letter-spacing: -1px;
        text-transform: uppercase;
        color: $primary-black;
        margin-bottom: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
        white-space: nowrap;
      }

      .browse-files {
        font-family: $poppins;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        text-align: center;
        letter-spacing: -1px;
        color: $primary-black;

        span {
          color: $blue;
        }
      }
    }

    .uploading-container {

      .upload-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;

        .progress {
          height: 100%;
          width: 0;
          transition: 1s width;
          background: $blue;
        }
      }
    }
  }
}

.bussiness-profile-form {
  display: flex;
  flex-direction: column;

  .state-standing-section {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-column-gap: 24px;

    .state-organization-container {
      padding: 12px;
      border-bottom: 1px solid $primary-black;
    }

    .state-organization-upload {
      padding: 8px 16px;

      .upload-file {
        padding-top: 6px;

        .upload-input-container {
          margin: auto;
        }
      }
    }

    .good-stand-content {
      padding: 12px;
      border-bottom: 1px solid $primary-black;

      .good-stand-head {
        margin-bottom: 12px;
      }

      .good-stand-text {
        margin-bottom: 12px;
      }

      .good-stand-radio {
        display: flex;

        .radio-container {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .good-stand-upload {
      padding: 12px;

      .good-stand-upload-text {
        margin-bottom: 18px;
      }

      .good-stand-upload-file {
        display: flex;
        justify-content: center;
      }
    }
  }

  .business-entity-section {

    .business-title {
      margin-bottom: 4px !important;
    }

    .section-sub-title {
      margin-bottom: 12px;
    }

    .section-content {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;

      .radio-container {
        margin-right: 24px;
        margin-bottom: 4px;
      }
    }
  }

  .governing-document-section {

    .governing-title {
      margin-bottom: 4px !important;
    }

    .section-sub-title {
      margin-bottom: 12px;
    }

    .section-block {

      .governing-documents {

        .document-upload-content {
          margin-bottom: 12px;
        }
      }
    }
  }

  .tax-reporting-section {

    .section-title {
      margin-bottom: 9px;
    }

    .section-sub-title {
      margin-bottom: 12px;
    }

    .section-content {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;

      .radio-container {
        margin-right: 24px;
        margin-bottom: 4px;
      }
    }
  }

  .revenue-profit-section {

    .annual-content {
      border: 1px solid $primary-black;
      padding: 12px;
    }

    .annual-radio {
      display: flex;
      margin-top: 18px;

      .radio-container {
        margin-right: 24px;
      }
    }
  }

  .statement-section {

    .section-content {
      padding: 16px;

      .statement-heading {
        letter-spacing: -1px;
        margin-bottom: 9px;
      }

      .statement-sub-heading {
        margin-bottom: 12px;
      }

      .statement-upload-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 84px;

        .statement-upload {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-right: 1px solid $primary-black;

          .statement-text {
            font-family: $poppins;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.8px;
            color: $primary-black;
            margin: 0 12px;
          }

          .upload-input-container {
            margin-top: auto;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .business-profile-note {
    font-family: $poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.75px;
    color: $primary-black;
    margin-bottom: 48px;
    text-align: center;
  }
}

.loan-rejected-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loan-rejected {
    background: $blue-dark;
    box-shadow: $reject-shadow;
    padding: 80px;
    width: 648px;
    box-sizing: border-box;
    margin-bottom: 100px;

    .rejected-header {
      font-family: $poppins;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.35px;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 32px;
    }

    .rejected-text {
      font-family: $poppins;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.5px;
      color: $white;
      opacity: 0.75;
      margin-bottom: 64px;
    }

    .rejected-contact {
      display: block;
      font-family: $poppins;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.4px;
      color: $white;
      opacity: 0.5;
      margin-bottom: 12px;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.email-confirm-bar {
  padding: 6px;
  text-align: center;
  background-color: $dark-white;
  font-family: $poppins;
  font-weight: 500;
  color: rgba($primary-black, 0.75);

  span {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 12px;
  }
}

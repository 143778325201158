.cover-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($primary-background-color, 0.4);
  left: 0;
  top: 0;
  z-index: 1;

  svg {
    animation: spin 2s cubic-bezier(0.25, 1, 1, 0.8) infinite;
  }
}

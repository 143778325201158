.loan-approved-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loan-approved {
    display: flex;
    flex-direction: column;
    width: 648px;
    background: $green-gradient;
    box-shadow: $box-shadow-button;
    padding: 0 92px;
    padding-top: 44px;
    padding-bottom: 32px;
    box-sizing: border-box;
    margin-bottom: 100px;

    .loan-icon {
      height: 91px;
      width: 91px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 42px;
    }

    .loan-official {
      font-family: $poppins;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.35px;
      text-transform: uppercase;
      color: $white;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .loan-text {
      font-family: $poppins;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.75px;
      color: $white;
      margin-bottom: 32px;
    }

    .loan-details {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba($white, 0.5);
      border-bottom: 1px solid rgba($white, 0.5);
      padding: 16px 32px;
      margin: 0 -48px;
      margin-bottom: 32px;

      .loan-detail-title {
        font-family: $poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $white;
        opacity: 0.75;
        margin-bottom: 12px;
      }

      .loan-detail-description {
        font-family: $poppins;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $white;
        max-width: 180px;
      }
    }

    .loan-dashboard {
      margin: auto;
      padding: 24px;
    }
  }
}

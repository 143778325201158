.admin-sub-navbar {
  display: flex;
  padding: 0 32px;
  background: $white;
  height: 52px;

  .sub-navbar-link {
    cursor: pointer;
    font-family: $poppins;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 16px 0;
    color: $blue-dark;
    margin-right: 48px;
    border-bottom: 2px solid transparent;

    &.active {
      font-weight: 600;
      border-bottom: 2px solid $blue;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
